import React, { useState } from 'react'
import { Link, animateScroll as scroll } from 'react-scroll'
import { NavLink } from 'react-router-dom'
import logo from '../assets/images/Stripped_Logo_Black--cropped.png'
import { StrippedConsumer } from '../context'

const MobileHeader = (props) => {
  const [isMenuOpen, toggleMenu] = useState(false)
  const closeMenu = () => toggleMenu(false)

  return(
    <StrippedConsumer>
      { value => {
        const { url } = value
        return (
          <>
          <div className='mobile-header__container'/>
          <div className={`mobile-header ${isMenuOpen && "mobile-header--active"}`}>
            <div className="mobile-header__main">
              <button className={`mobile-header__button hamburger hamburger--collapse ${isMenuOpen && "is-active"}`} type="button" onClick={() => toggleMenu(!isMenuOpen)}>
                <span className="hamburger-box">
                  <span className="hamburger-inner"></span>
                </span>
              </button>
              <img src={logo} alt="Logo" className="mobile-header__logo" />
            </div>
            { isMenuOpen && (
              <div className="mobile-header__content">
                <Link onClick={closeMenu} to="main" spy={true} offset={-200} activeClass="mobile-header__link--active" className="mobile-header__link"><NavLink to="/">HOME</NavLink></Link>
                <a onClick={closeMenu} href={url} target="_blank" rel="noopener noreferrer" className="mobile-header__link">BOOK</a>
                <Link onClick={closeMenu} to="services" spy={true} offset={-200} activeClass="mobile-header__link--active" className="mobile-header__link"><NavLink to="/">SERVICES</NavLink></Link>
                <NavLink onClick={closeMenu} exact to="/products" activeClassName="mobile-header__link--active" className="mobile-header__link">PRODUCTS</NavLink>
                <NavLink onClick={closeMenu}  exact to="/strands" activeClass="mobile-header__link--active" className="mobile-header__link">PERMANENT JEWELRY</NavLink>
                {/* <Link onClick={closeMenu} to="about" spy={true} activeClass="mobile-header__link--active" className="mobile-header__link">TESTIMONIALS</Link> */}
                <Link onClick={closeMenu} to="contact" spy={true} offset={-165} activeClass="mobile-header__link--active" className="mobile-header__link"><NavLink to="/">LOCATION</NavLink></Link>
              </div>
            )}
          </div>
          </>
        )
      }}
    </StrippedConsumer>
  )
}

export default MobileHeader