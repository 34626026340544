import React from 'react';
import ReactDOM from 'react-dom';
import App from './components/App';
import { StrippedProvider } from './context';
import 'hamburgers'
import './assets/fonts/MoonTime.ttf'

ReactDOM.render(
  <React.StrictMode>
    <StrippedProvider>
      <App />
    </StrippedProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

