import React, { Suspense, useContext, useEffect } from 'react'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import Header from './Header'
import '../assets/sass/main.scss'
import MobileHeader from './MobileHeader'
import { StrippedContext } from '../context'

const Strands = React.lazy(() => import('./Strands'))
const Products = React.lazy(() => import('./Products'))
const Main = React.lazy(() => import('./Main'))

const App = () => {
  const { isLoading } = useContext(StrippedContext)

  if (isLoading) return null

  return (
    <Suspense fallback={<div>Loading...</div>}>
      <Router>
        <div className="stripped">
          <Header />
          <MobileHeader />
          <Switch>
            <Route path="/products">
              <Products />
            </Route>
            <Route path="/strands">
              <Strands />
            </Route>
            <Route exact path="/">
              <Main />
            </Route>
          </Switch>
        </div>
      </Router>
    </Suspense>
  )
}

export default App
