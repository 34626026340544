import React, { useContext } from 'react'
import { Link, animateScroll as scroll } from 'react-scroll'
import { NavLink } from 'react-router-dom'
import logo from '../assets/images/Stripped_Logo_Black--cropped.png'
import { StrippedConsumer, StrippedContext } from '../context'

const Header = () => {
  const { url } = useContext(StrippedContext)
  return (
    <>
    <div className='header__container' />
    <div className="header">
      <div className="header__logo-container"><img src={logo} alt="Logo" className="header__logo" /></div>
      <div className="header__link-container">
        <Link to="main" spy={true} offset={-200} activeClass="header__link--active" className="header__link"><NavLink to="/">HOME</NavLink></Link>
        <a href={url} target="_blank" rel="noopener noreferrer" className="header__link">BOOK</a>
        <Link to="services" spy={true} offset={-200} activeClass="header__link--active" className="header__link"><NavLink to="/">SERVICES</NavLink></Link>
        <NavLink exact to="/products" activeClassName="header__link--active" className="header__link">PRODUCTS</NavLink>
        <NavLink exact to="/strands" activeClass="header__link--active" className="header__link">PERMANENT JEWELRY</NavLink>
        <Link to="contact" spy={true} offset={-165} activeClass="header__link--active" className="header__link"><NavLink to="/">LOCATION</NavLink></Link>
      </div>
    </div>
    </>
  )
}

export default Header