import React, { useState, useEffect } from 'react'
import { shuffle } from './utils/array-utils'
import Client from './Contentful'

const StrippedContext = React.createContext()

const StrippedProvider = ({ children }) => {
  const [url, setUrl] = useState('')
  const [hours, setHours] = useState([])
  const [services, setServices] = useState([])
  const [filteredServices, setFilteredServices] = useState([])
  const [products, setProducts] = useState([])
  const [categories, setCategories] = useState([])
  const [strands, setStrands] = useState(null)
  const [contact, setContact] = useState(null)
  const [selectedCategory, setSelectedCategory] = useState('waxing')
  const [isLoading, setLoading] = useState(true)

  const formatProductData = (items) => {
    let tempItems = items.map(item => {
      let id = item.sys.id
      let image = item.fields.images.fields.file.url
      let data = { ...item.fields, image, id }
      return data
    })
    return tempItems
  }

  const formatServicesData = (items) => {
    let tempItems = items.map(item => {
      let id = item.sys.id
      let data = { ...item.fields, id }
      return data
    })
    return tempItems
  }

  const formatHoursData = (items) => {
    let tempItems = items.map(item => {
      let id = item.sys.id
      let data = { ...item.fields, id }
      return data
    })
    return tempItems
  }

  const formatBookingURL = (item) => {
    return item[0].fields.url
  }

  const formatContact = item => {
    const _item = item[0].fields
    const map = item[0].fields.map.fields.file
    return { ..._item, map }
  }

  const formatStrandsResponse = items => {
    const strandImages = items.map(item => {
      let id = item.sys.id
      let image = item.fields.images.fields.file.url
      let data = { ...item.fields, image, id }
      return data
    })
    
    return strandImages
  }

  const getServices = () => {
    return services.filter(service => service.category === selectedCategory.toLowerCase())
  }

  const getData = async () => {
    try {
      let [productsResponse, servicesResponse, hoursResponse, bookingURLResponse, contactResponse, strandResponse] = await Promise.all([
        Client.getEntries({ content_type: 'strippedProducts' }),
        Client.getEntries({ content_type: 'strippedServices', order: 'fields.order' }),
        Client.getEntries({ content_type: 'strippedHours' }),
        Client.getEntries({ content_type: 'bookingUrl' }),
        Client.getEntries({ content_type: 'strippedContact' }),
        Client.getEntries({ content_type: 'strippedStrands' })
      ])

      let products = formatProductData(productsResponse.items)
      let services = formatServicesData(servicesResponse.items)
      let hours = formatHoursData(hoursResponse.items)
      let bookingUrl = formatBookingURL(bookingURLResponse.items)
      let contact = formatContact(contactResponse.items)
      let strands = formatStrandsResponse(strandResponse.items)
  
      setServices(services)
      setProducts(products)
      setHours(hours)
      setUrl(bookingUrl)
      setContact(contact)
      setStrands(shuffle(strands))
      setLoading(false)
    } catch (err) {
      console.log(err)
    }
  }

  useEffect(() => {
    getData()
  }, [])

  useEffect(() => {
    let filteredServicesByCategory = getServices()
    setFilteredServices(filteredServicesByCategory)
  }, [isLoading, selectedCategory])

  const handleCategoryChange = (category) => setSelectedCategory(category)

  const getCategories = () => {
    const categories = []
    return categories
  }

  const values = {
    url,
    hours,
    services,
    products,
    strands,
    contact,
    categories,
    selectedCategory,
    filteredServices,
    handleCategoryChange,
    getServices,
    getCategories
  }

  return (
    <StrippedContext.Provider value={values}>
      { children }
    </StrippedContext.Provider>
  )
}

const StrippedConsumer = StrippedContext.Consumer

export { StrippedProvider, StrippedConsumer, StrippedContext }